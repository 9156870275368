import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import axios from "axios";
import Header from "../Header/Header";
import { Chart } from "chart.js/auto";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown, faComment } from "@fortawesome/free-solid-svg-icons";
import Vote from "./Vote";
import { useParams } from "react-router-dom";
import isBlacklisted from "../../util/Blacklist";
import { useNavigate } from "react-router-dom";
import Spinner from "../../util/Spinner";
import ".././SharedCss.css";
import "./Post.css";

function Post() {

  const {id} = useParams();
  const [currentPost, setCurrentPost] = useState();
  const [currentPostComments, setComments] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [comment, setComment] = useState("");
  const [loadingAddComment, SetLoadingAddComment] = useState(false);
  const [currentPostComments1, setComments1] = useState([]);

  const [poll, setPoll] = useState([]);
  const [pollChart, setPollChart] = useState(null);
  const [openVote, setOpenVote] = useState(false);
  const votePopUpRef = useRef();
  const siteRef = useRef(null);
  const [CommentSelectedimage, setCommentImage] = useState([]);
  const [postImage, setPostImage] = useState([]);
  const [postImageIndex, setPostImageIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [images,  setImages] = useState([]);

  const [loggedIn, setAuthenticated] = useState();
  const [userId, setUserId] = useState();

    useEffect(() => {
      if(sessionStorage.getItem("isAuthenticated") == null)
        {
          setAuthenticated("false");
        }
        else{
          setUserId(sessionStorage.getItem("userId"));
          setAuthenticated(sessionStorage.getItem("isAuthenticated"));    
        }    
        const handleLocalStorageUpdate = () => {
        setAuthenticated(true)
      };


      setAuthenticated("true")

      window.addEventListener('localStorageUpdate', handleLocalStorageUpdate);
      return () => {
        window.removeEventListener('localStorageUpdate', handleLocalStorageUpdate);
      };
      
    }, []);

  useEffect(()=>{
    async function GetImage(){
      await axios
      .get(process.env.REACT_APP_REQUEST_URL + "/Image/get-image/" + id + "/-1000")
      .then((resp) => {
       const imageData = resp.data.split('delimiter');
       imageData.map((o,index)=>  setPostImage((prevImages) => [...prevImages, imageData[index]]));
      })
      .catch((error) => {
        // Handle the error as needed
      });
    }

    GetImage();
  }, [id]);


  const backgroundColor =  ["#8ecae6", "#219ebc", "#023047", "#ffb703", "#fb8500", "#606c38", "#283618", "#fefae0", "#dda15e", "#bc6c25", "#cdb4db", "#ffc8dd"];

  useEffect(() => {

      if (poll.length > 0) {
        const pollData = poll.map((item) => ({
          description: item.description,
          votes: item.votes,
        }));
  
        const chartLabels = pollData.map((item) => item.description);
        const chartData = pollData.map((item) => item.votes);
  

        if (pollChart) {
          pollChart.destroy();
        }

          const ctx = document.getElementById("pollChart").getContext("2d");
          const newPollChart = new Chart(ctx, {
            type: "pie",
            data: {
              labels: chartLabels,
              datasets: [
                {
                  label: "Röster",
                  data: chartData,
                  backgroundColor: pollData.map((vote, index) => backgroundColor[index % backgroundColor.length]),
                  borderWidth: 1,
                },
              ],
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                tooltip: {},
              },
            },
          });
          setPollChart(newPollChart);
          setLoading(false)
    }
  }, [poll]);


  useEffect(() => {
    document.body.addEventListener("click", onClickOutside);
    async function GetPost()
    {
      await axios
      .get(process.env.REACT_APP_REQUEST_URL + "/post/get-post/" + id)
      .then((resp) => {
        setCurrentPost(resp.data);
      });
    setRefresh(false);
    }

    GetPost();

  }, [id, refresh]);

  useEffect(() => {
    async function GetCommentsOfPost()
    {
      await axios
      .get(process.env.REACT_APP_REQUEST_URL + "/comment/get-comments-by-post-id/" + id)
      .then((resp) => {

        const updatedPosts = resp.data.map(post => {
          if (post.images) {
            const imageData = post.images.split('delimiter');
            return { ...post, images: imageData, imageIndex: 0  };
          } else {
            return post;
          }
        });

        setComments(updatedPosts);
      });
    }
    async function GetPoll(){
      await axios
      .get(process.env.REACT_APP_REQUEST_URL + "/post/get-poll/" + id)
      .then((resp) => {
        setTimeout(() => {
          setPoll(resp.data);
        }, 2000);
      });
    }

    GetCommentsOfPost();
    GetPoll();

    setRefresh(false);
  }, [id, refresh]);

  async function addComment() {
    /*const isBlacklistedPost = isBlacklisted(comment);

    if (isBlacklistedPost) {
      // Handle the case where the post contains blacklisted words
    } else {
      // Proceed with submitting the post
    }*/

    if(comment.length > 3 && loggedIn)
    {
      SetLoadingAddComment(true);
      if(CommentSelectedimage.length > 0){
        const formData = new FormData();

        CommentSelectedimage.forEach((image, index) => {
          formData.append(`image`, image);
        });

        formData.append("comment", JSON.stringify({
          content: comment,
          user: { id: parseInt(userId) },
          post: { id: id },
        }));   
        
        await axios.post(
          process.env.REACT_APP_REQUEST_URL + `/comment/add-comment-image/${id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }
      else {
        await axios.post(process.env.REACT_APP_REQUEST_URL + "/comment/add-comment", {
          content: comment,
          user: { id: parseInt(userId) },
          post: { id: id },
        });
      }
      SetLoadingAddComment(false);
      setRefresh(true);
      setComment("");
      setCommentImage([]);
    }
  }

  async function upvotePost() {
    if(parseInt(userId) != currentPost.userId)
    {
      await axios
      .post(process.env.REACT_APP_REQUEST_URL + "/post/upvote-post/" + id + "/" + parseInt(userId))
      .then((resp) => {
        if (resp.data === true) {
          setRefresh(true);
        }
      });
    }
  }

  async function downvotePost() {
    if(currentPost.votes - 1  >= 0 && (parseInt(userId) != currentPost.userId)){
      await axios
      .post(process.env.REACT_APP_REQUEST_URL + "/post/downvote-post/" + id + "/" + parseInt(userId))
      .then((resp) => {
        if (resp.data === true) {
          setRefresh(true);
        }
      });
    }
  }

  async function upvoteComment(e) {
    await axios
      .post(process.env.REACT_APP_REQUEST_URL + "/comment/vote-on-comment/"+ parseInt(userId) + "/" + e)
      .then((resp) => {
        if (resp.data === true) {
          setRefresh(true);
        }
      });
  }

  async function downvoteComment(e,vote) {
    if(vote - 1 >=0){
      await axios
      .post(process.env.REACT_APP_REQUEST_URL + "/comment/downvote-on-comment/" + parseInt(userId) + "/" + e)
      .then((resp) => {
        if (resp.data === true) {
          setRefresh(true);
        }
      });
    }
  }

  function sortNew() {
    const sorted = [...currentPostComments].sort((a, b) => {
      return a.votes - b.votes;
    });
    setComments(sorted);
  }

  function sortTop() {
    const sorted = [...currentPostComments].sort((a, b) => {
      return b.votes - a.votes;
    });
    setComments(sorted);
  }

  function openVotePopup() {
    setOpenVote(true);
  }

  function onClickOutside(e) {

    if (votePopUpRef.current && !votePopUpRef.current.contains(e.target)) {      
      setOpenVote(false);
    }
  }

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    if(selectedImage != null){
      if (selectedImage.size < 15728640) {
        // Image size is acceptable, add it to the state
        if(CommentSelectedimage.length < 10)
          setCommentImage((images) => [...images, selectedImage]);
      } else {
        // Image size exceeds the limit
        alert("Please select an image smaller than 1 MB.");
      }
    }
  };

  const SwitchPicture = (e) => {
    const newIndex = e + postImageIndex;
    if(newIndex >= 0 && newIndex < postImage.length){
      setPostImageIndex(newIndex);
    }
    else if (postImage.length <= newIndex) {
      setPostImageIndex(0);
    }
    else if(newIndex <= 0)
    {
      setPostImageIndex(postImage.length-1)
    }
  }

  const switchPicture2 = (postIndex, direction) => {
    setComments(prevPosts => {
        const updatedPosts = [...prevPosts];
        const newIndex = updatedPosts[postIndex].imageIndex + direction;
        const length = updatedPosts[postIndex].images.length;
        updatedPosts[postIndex].imageIndex = (newIndex + length) % length;
        return updatedPosts;
    });
  };

  const searchUser = (input) =>{
    var userId;
    if(input.length !== undefined)
    {
      userId = input;
    }
    else{
      userId = document.getElementById("search-user").value;
    }

    if(userId.length > 0 && userId != 0){
      navigate("/profil/"+userId);
    }
  }

  if (!currentPost || currentPost === undefined || !currentPostComments) {
    return (
      <div id="site">
        <Header />
        <div >
          <Spinner />
        </div>
      </div>
    );
  }

  return (       
    <div id="site">
        <Header />
        <div ref={votePopUpRef} className="vote-popup-post">
            {openVote ? <Vote values={{id,userId}} /> : null}
        </div>
        <div id="post-with-id" className="post-container">
            <div className="post-header" onClick={(e) => e.stopPropagation()}>
                <div id="post-userid" onClick={() => searchUser(currentPost.userId)}>{"# " + currentPost.userId}</div>
            </div>
            <div id="line"></div>
            <div
        className="post-content"
        dangerouslySetInnerHTML={{ __html: currentPost.content }}
    ></div>            
            {poll.length > 0 ?                     <div id="pollchart-area">

<div id="chartArea">
    <canvas id="pollChart"></canvas>
</div>

<div id="chart-area-button">
    {loggedIn != "false" ? <button ref={votePopUpRef} id="vote-on-post" onClick={openVotePopup}>
        Rösta
    </button> : null}
</div>
</div>: null}


            {postImage != null && postImage.length >= 1 && (              
                <div className="image-container" onClick={(e) => e.stopPropagation()}>
                    <div id="line-for-images"></div>
                    <div id="picture-switch-buttons-area">
                        <button id="picture-switch-buttons" onClick={() => SwitchPicture(-1)}> {'<'} </button>
                        <button id="picture-switch-buttons" onClick={() => SwitchPicture(1)}> {'>'} </button>
                        <span id="picture-switch-buttons">{postImage != null && (postImageIndex+1 + "/" + postImage.length)}</span>
                    </div>
                    <img
                        id="post-picture-area"
                        src={`data:image/jpeg;base64,${postImage[postImageIndex]}`}
                        alt={`Post ${1}`}
                        style={{
                          width: '36vw',  
                          height: '60vh'
                        }}
                    />
                </div>
            )}
            <div id="line"></div>
            <div className="post-footer">
                <div id="post-vote">
                  
                  {loggedIn != "false" ? 
                  <div style={{display:"flex"}}>
                  <button className="upvote-btn" onClick={upvotePost}>
                    <FontAwesomeIcon id="vote-icons" icon={faArrowUp} />
                  </button>
                  <button className="downvote-btn" onClick={downvotePost}>
                    <FontAwesomeIcon id="vote-icons" icon={faArrowDown} />
                  </button>
                  </div>
                : null}

                </div>
                <div id="post-votes">{currentPost.votes}</div>
                <div id="post-date">{currentPost.time}</div>
            </div>
        </div>

{
  loggedIn != "false" ? (
    <div>
      <div id="add-comment-area">
        <textarea
          id="add-comment-input"
          type="text"
          placeholder="Kommentar!"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          maxLength={500}
        />

        <button
          title="Lägg till kommentar!"
          id="add-comment-button"
          onClick={addComment}
          disabled={loadingAddComment} // Disable button when loading
        >
          {loadingAddComment ? (
            <div className="loader"></div> // Show spinner when loading
          ) : (
            <FontAwesomeIcon icon={faComment} /> // Show icon when not loading
          )}
        </button>
          </div>



          <div id="image-upload-area">

        <label htmlFor="imageInput">
          <div className="custom-button custom-button-post">Välj bild</div>
        </label>
        <input
          type="file"
          accept="image/jpeg, image/png"
          id="imageInput"
          onChange={handleImageChange}
          style={{ display: 'none' }}
        />

          <div className="image-grid">
            {CommentSelectedimage.map((image, currentImageIndex) => (
              <div key={currentImageIndex}>
                <button
                  id="new-post-images"
                  onClick={(e) =>
                    setCommentImage((prevImages) =>
                      prevImages.filter((_, index) => index !== currentImageIndex)
                    )
                  }
                >
                  {image.name.substring(0, 10)}
                </button>
              </div>
            ))}
          </div>
        </div>
    </div>
  ) : null
}

      
        <button id="sort-filter" onClick={sortTop}>
            Top
        </button>
        <button id="sort-filter" onClick={sortNew}>
            New
        </button>
        <div id="posts">
      {currentPostComments.map((index, key) => (
        <div
          id="post"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={{ delay: key * 0.6 }}
          className="post-container"
        >
          <div className="post-header" onClick={(e) => e.stopPropagation()}>
            <div id="post-userid" onClick={()=>searchUser(index.userId)}>{"# " + index.userId}</div>
          </div>
          <div id="line"></div>
          <p className="post-content">{index.content}</p>
          {index.images != null && index.images.length >= 1 && (
            <div className="image-container" onClick={(e) => e.stopPropagation()}>
              <div id="line-for-images"></div>
              <div id="picture-switch-buttons-area">
                <button id="picture-switch-buttons" onClick={() => switchPicture2(key, -1)}> {'<'} </button>
                <button id="picture-switch-buttons" onClick={() => switchPicture2(key, 1)}> {'>'} </button>
                <span id="picture-switch-buttons">{index.images != null && (index.imageIndex + 1 + "/" + index.images.length)}</span>
              </div>
              <img
                id="post-picture-area"
                src={`data:image/jpeg;base64,${index.images[index.imageIndex]}`}
                alt={`Post ${key + 1}`}
                style={{
                  width: '36vw',  
                  height: '60vh'
                }}
              />

            </div>
          )}
          <div id="line"></div>
          <div className="post-footer">
          <div id="post-vote">


          {loggedIn != "false" ? 
                  <div style={{display:"flex"}}>
                    <button className="upvote-btn" onClick={() =>upvoteComment(key+1)}>
                        <FontAwesomeIcon id="vote-icons" icon={faArrowUp} />
                    </button>
                    <button className="downvote-btn" onClick={() =>downvoteComment(key+1,index.votes)}>
                        <FontAwesomeIcon id="vote-icons" icon={faArrowDown} />
                    </button>
                  </div>
                : null}
                </div>
            <div id="post-votes">{index.votes}</div>
            <div id="post-date">{index.time}</div>
          </div>
        </div>
      ))}
    </div>
    </div>
  );
}

export default Post;
