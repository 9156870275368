import { useEffect } from "react";
import { useParams,useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import "./UserProfile.css";
import Header from "../Header/Header";
import PollChart from "../frontpage/PollChart";

function UserProfile(){

    const initialValue = [{ userId: 0, content: "" }];
    const {userId} = useParams();
    const [posts, setPosts] = useState([initialValue]);
    const navigate = useNavigate();
    const [idOfUser,setIdOfUser] = useState();
    const [socialPoints,setSocialPoints] = useState();

    const backgroundColor = [
        "#8ecae6",
        "#219ebc",
        "#023047",
        "#ffb703",
        "#fb8500",
        "#606c38",
        "#283618",
        "#fefae0",
        "#dda15e",
        "#bc6c25",
        "#cdb4db",
        "#ffc8dd",
      ];
    
    useEffect(()=>{
        axios.get(process.env.REACT_APP_REQUEST_URL +  "/user/get-profile/" + userId).then(function(response){
            if(response.data.length !== undefined)
            {
                navigate(-1);
                return;
            }

            setIdOfUser(response.data.user.id);
            setSocialPoints(response.data.user.socialPoints);
            const updatedPosts = response.data.posts.map(post => {
                if (post.images) {
                const imageData = post.images.split('delimiter');
                return { ...post, images: imageData, imageIndex: 0  };
                } else {
                return post;
                }
            });
            setPosts(updatedPosts);
        });

    },[userId])

    function getPostById(id) {
        navigate("/snack/"+id);
    }

    const switchPicture = (postIndex, direction) => {
        setPosts(prevPosts => {
            const updatedPosts = [...prevPosts];
            const newIndex = updatedPosts[postIndex].imageIndex + direction;
            const length = updatedPosts[postIndex].images.length;
            updatedPosts[postIndex].imageIndex = (newIndex + length) % length;
            return updatedPosts;
        });
      };

    return(
        <div id="site">
            <Header/>
            <div id="profile-id-border">
                <div id="profile-id"><span id="profile-id-nr"># </span> {idOfUser}</div>
                <div id="profile-id">$ {socialPoints}</div>
            </div>

            <div id="posts">
                {posts.map((index, key) => (
                    <div
                    id="post"
                    className="post-container p-6 bg-gray-900 rounded-lg my-6"
                    onClick={() => {
                        getPostById(index.postId);
                    }}
                    >
                    <div className="post-header">
                        <div id="post-userid">{"# " + index.userId}</div>
                    </div>
                    <div id="line"></div>
                    <div
                    className="post-content"
                    dangerouslySetInnerHTML={{ __html: index.content }}
                ></div>                 
                {index.innerVoteDTO != null && index.innerVoteDTO.length > 0 ? (
                <div id="pollchart-area">
                  <PollChart
                    data={{
                      labels: index.innerVoteDTO.map((vote) => vote.description),
                      datasets: [
                        {
                          label: "Votes",
                          data: index.innerVoteDTO.map((vote) => vote.votes),
                          backgroundColor: index.innerVoteDTO.map(
                            (vote, index) => backgroundColor[index % backgroundColor.length]
                          ),
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      plugins: {
                        tooltip: {},
                      },
                    }}
                  />
                </div>
              ) : null}

                    {index.images != null && index.images.length >= 1 && (
                        <div className="image-container" onClick={(e) => e.stopPropagation()}>
                        <div id="line-for-images"></div>
                        <div id="picture-switch-buttons-area">
                            <button id="picture-switch-buttons" onClick={() => switchPicture(key, -1)}> {'<'} </button>
                            <button id="picture-switch-buttons" onClick={() => switchPicture(key, 1)}> {'>'} </button>
                            <span id="picture-switch-buttons">{index.images != null && (index.imageIndex + 1 + "/" + index.images.length)}</span>
                        </div>
                        <img
                            id="post-picture-area"
                            src={`data:image/jpeg;base64,${index.images[index.imageIndex]}`}
                            alt={`Post ${key + 1}`}
                            width={800}
                            height={800}
                        />
                        </div>
                    )}
                    <div id="line"></div>
                    <div className="post-footer">
                        <div id="post-votes">{index.votes}</div>
                        <div id="post-date">{index.time}</div>
                    </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default UserProfile;