import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import "./Header.css";
import axios from "axios";
import { useAuth0 } from '@auth0/auth0-react';

export default function Header() { 
  const navigate = useNavigate();
  const siteRef = useRef(null);
  const [userId, setUserId] = useState();
  const { loginWithRedirect, logout, user, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (sessionStorage.getItem("isAuthenticated") == null) {
    } else {
      setUserId(sessionStorage.getItem("userId"));
    }
  }, []);

  const handleLogout = () => {
    sessionStorage.clear();
    logout({ returnTo: window.location.origin });
  };

  function headerLogoClicked() {
    navigate("/");
  }

  useEffect(() => {
    if (isAuthenticated) {
      loginTest();
    }
  }, [isAuthenticated]);

  async function loginTest() {
    if (user != null && isAuthenticated) {
      const userData = { email: user.email };
  
      await axios.post(process.env.REACT_APP_REQUEST_URL + "/user/login", userData).then((resp) => {
        sessionStorage.setItem("userId", resp.data);
        setUserId(resp.data);
        sessionStorage.setItem("isAuthenticated", "true");
        const event = new Event('localStorageUpdate');
        window.dispatchEvent(event);
      });
    }
  }

  return (
    <div>
      <div
        style={{
          width: "88vw",
          position: "relative",
          margin: "auto",
        }}
        ref={siteRef}
      >
        {!isAuthenticated && (
          <div>
            <button id="login-button" onClick={() => loginWithRedirect()}>Log In</button>
          </div>
        )}
        {isAuthenticated && (
          <div>
            <button id="login-button" onClick={handleLogout}>Log Out</button>
            <div>
              <div id="profile-id-header">
                <span id="profile-id-nr-header"># </span>
                {userId != null ? userId.toString().padStart(4, '0') : null}
              </div>
            </div>
          </div>
        )}

        <div style={{textAlign: "center" }}>
          <div style={{ display: "flex", alignItems: "center", margin: "auto" }}>
            <div style={{ flex: 1, backgroundColor: "#282928", height: "0.3vh" }} />
            <button id="logoButton" onClick={headerLogoClicked}>Snacka.nu</button>
            <div style={{ flex: 1, backgroundColor: "#282928", height: "0.3vh" }} />
          </div>
          <div style={{ marginBottom: "1%", fontSize: "2vh",fontFamily: "Impact",color: "var(--primary-color)"}}>Made by Bouya</div>
        </div>
      </div>
    </div>
  );
}
