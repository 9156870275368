import "./FrontPage.css";
import ".././SharedCss.css";
import Spinner from "../../util/Spinner";


import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import PollChart from "./PollChart";

import Header from "../Header/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFire, faMagnifyingGlass, faBarsProgress } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";

function FrontPage() {
  const initialValue = [{ userId: 0, content: "" }];
  const [posts, setPosts] = useState([initialValue]);
  const [loggedIn, setAuthenticated] = useState();
  const navigate = useNavigate();
  const [loading, SetIsLoading] = useState(true);

  useEffect(() => {
    if (sessionStorage.getItem("isAuthenticated") == null) {
      setAuthenticated("false");
    } else {
      setAuthenticated(sessionStorage.getItem("isAuthenticated"));
    }

    const handleLocalStorageUpdate = () => {
      setAuthenticated(true);
    };

    hotPost();

    window.addEventListener("localStorageUpdate", handleLocalStorageUpdate);

    return () => {
      window.removeEventListener("localStorageUpdate", handleLocalStorageUpdate);
    };
  }, []);

  function getPostById(id) {
    navigate("/snack/" + id);
  }

  function newPost() {
    navigate("/skapa");
  }

  async function hotPost() {
    SetIsLoading(true);
    await axios
      .get(process.env.REACT_APP_REQUEST_URL + "/post/hottest-posts")
      .then(function (response) {
        const updatedPosts = response.data.map((post) => {
          if (post.images) {
            const imageData = post.images.split("delimiter");
            return { ...post, images: imageData, imageIndex: 0 };
          } else {
            return post;
          }
        });
        setPosts(updatedPosts);
      });
      SetIsLoading(false);
  }

  async function newestPosts() {
    SetIsLoading(true);
    await axios
      .get(process.env.REACT_APP_REQUEST_URL + "/post/get-latest-posts")
      .then(function (response) {
        const updatedPosts = response.data.map((post) => {
          if (post.images) {
            const imageData = post.images.split("delimiter");
            return { ...post, images: imageData, imageIndex: 0 };
          } else {
            return post;
          }
        });
        setPosts(updatedPosts);
      });
      SetIsLoading(false);
  }

  async function topPosts() {
    SetIsLoading(true);
    await axios
      .get(process.env.REACT_APP_REQUEST_URL + "/post/get-top-posts")
      .then(function (response) {
        const updatedPosts = response.data.map((post) => {
          if (post.images) {
            const imageData = post.images.split("delimiter");
            return { ...post, images: imageData, imageIndex: 0 };
          } else {
            return post;
          }
        });
        setPosts(updatedPosts);
      });
      SetIsLoading(false);
  }

  const switchPicture = (postIndex, direction) => {
    setPosts((prevPosts) => {
      const updatedPosts = [...prevPosts];
      const newIndex = updatedPosts[postIndex].imageIndex + direction;
      const length = updatedPosts[postIndex].images.length;
      updatedPosts[postIndex].imageIndex = (newIndex + length) % length;
      return updatedPosts;
    });
  };

  const searchUser = (input) => {
    var userId;
    if (input.length !== undefined) {
      userId = input;
    } else {
      userId = document.getElementById("search-user").value;
    }

    let isnum = /^\d+$/.test(userId);

    if (userId.length > 0 && userId != 0 && isnum) {
      navigate("/profil/" + userId);
    }
  };

  const searchUser2 = (e) => {
    var userId;
    if(e.key == "Enter")
    {
      userId = document.getElementById("search-user").value;
      
      let isnum = /^\d+$/.test(userId);

      if (userId.length > 0 && userId != 0 && isnum) {
        navigate("/profil/" + userId);
      }
    }
  };

  const backgroundColor = [
    "#8ecae6",
    "#219ebc",
    "#023047",
    "#ffb703",
    "#fb8500",
    "#606c38",
    "#283618",
    "#fefae0",
    "#dda15e",
    "#bc6c25",
    "#cdb4db",
    "#ffc8dd",
  ];

  if (loading) {
    return (
      <div id="site">
        <Header />
        <div >
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <div id="site">
      <Header />

      <div id="search-wrapper">
        <input type="text" id="search-user" onKeyUp={searchUser2} placeholder="Sök användare" />
        <button id="search-button" onClick={searchUser}>
          <FontAwesomeIcon id="search-button-icon" icon={faMagnifyingGlass} />
        </button>
      </div>

      {loggedIn != "false" ? (
        <button id="new-post" onClick={newPost}>
          Nytt Snack
        </button>
      ) : null}

      <div id="post-container">
      <div id="category-buttons">
  <button onClick={hotPost} id="categoryButton1" title="Heta Snack">
    <FontAwesomeIcon icon={faFire} id="category-button-icon" />
  </button>
  <button onClick={newestPosts} id="categoryButton2" title="Senaste Snack">
    <FontAwesomeIcon icon={faClock} id="category-button-icon" />
  </button>
  <button onClick={topPosts} id="categoryButton3" title="Top Snack">
    <FontAwesomeIcon icon={faBarsProgress} id="category-button-icon" />
  </button>
</div>

        <div id="posts">
          {posts.map((index, key) => (
            <div
              id="post"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ delay: key * 0.6 }}
              className="post-container"
              onClick={() => {
                getPostById(index.postId);
              }}
            >
              <div className="post-header" onClick={(e) => e.stopPropagation()}>
                <div id="post-userid" onClick={() => searchUser(index.userId)}>
                  {"# " + index.userId}
                </div>
              </div>
              <div id="line"></div>
              <div
        className="post-content"
        dangerouslySetInnerHTML={{ __html: index.content }}
    ></div>              {index.innerVoteDTO != null && index.innerVoteDTO.length > 0 ? (
                <div id="pollchart-area">
                  <PollChart
                    data={{
                      labels: index.innerVoteDTO.map((vote) => vote.description),
                      datasets: [
                        {
                          label: "Votes",
                          data: index.innerVoteDTO.map((vote) => vote.votes),
                          backgroundColor: index.innerVoteDTO.map(
                            (vote, index) => backgroundColor[index % backgroundColor.length]
                          ),
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      plugins: {
                        tooltip: {},
                      },
                    }}
                  />
                </div>
              ) : null}

              {index.images != null && index.images.length >= 1 && (
                <div className="image-container" onClick={(e) => e.stopPropagation()}>
                  <div id="line-for-images"></div>
                  <div id="picture-switch-buttons-area">
                    <button id="picture-switch-buttons" onClick={() => switchPicture(key, -1)}>
                      {"<"}
                    </button>
                    <button id="picture-switch-buttons" onClick={() => switchPicture(key, 1)}>
                      {">"}
                    </button>
                    <span id="picture-switch-buttons">
                      {index.images != null && index.imageIndex + 1 + "/" + index.images.length}
                    </span>
                  </div>
                  <img
                    id="post-picture-area"
                    src={`data:image/jpeg;base64,${index.images[index.imageIndex]}`}
                    alt={`Post ${key + 1}`}
                    style={{
                      width: "36vw",
                      height: "60vh",
                    }}
                  />
                </div>
              )}
              <div id="line"></div>
              <div className="post-footer">
                <div id="post-votes">{index.votes}</div>
                <div id="post-date">{index.time}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FrontPage;
