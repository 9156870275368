import isBlacklisted from "../../util/Blacklist";
import Header from "../Header/Header";
import "./NewPost.css";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import TextStyle from '@tiptap/extension-text-style';
import Color from '@tiptap/extension-color';
import ListItem from '@tiptap/extension-list-item';
import Spinner from "../../util/Spinner";

function NewPost() {
  const [post, setPostMessage] = useState("");
  const [postCreated, setPostCreated] = useState(false);
  const [voteInput, setVoteInput] = useState("");
  const [voteArray, setVoteArray] = useState([]);
  const [voteChecked, setVoteChecked] = useState(false);
  const [images, setImages] = useState([]);
  const [postCreatedMessage, setPostCreatedMessage] = useState("");
  const [loading, SetIsLoading] = useState(false);

  const navigate = useNavigate();


  const [loggedIn, setAuthenticated] = useState();
  const [userId, setUserId] = useState();


  const editor = useEditor({
    extensions: [
      StarterKit,
      TextStyle,
      Color,
      ListItem,
    ],
    content: '<p>Ny post</p>',
    onUpdate: ({ editor }) => {
      setPostMessage(editor.getHTML());
    },
  });

  const applyTextColor = (color) => {
    editor.chain().focus().setColor(color).run();
  };

  useEffect(() => {
    return () => editor?.destroy();
  }, [editor]);


  useEffect(() => {
    if (sessionStorage.getItem("isAuthenticated") == null) {
      setAuthenticated("false");
    } else {
      setUserId(sessionStorage.getItem("userId"));
      setAuthenticated(sessionStorage.getItem("isAuthenticated"));
    }

    const handleLocalStorageUpdate = () => {
      setAuthenticated(true);
    };

    window.addEventListener("localStorageUpdate", handleLocalStorageUpdate);

    return () => {
      window.removeEventListener("localStorageUpdate", handleLocalStorageUpdate);
    };
  }, []);

  async function submitPost() {
    let postId = 0;
    SetIsLoading(true)
    if (post.length >= 5 || !loggedIn) {
      const isBlacklistedPost = isBlacklisted(post);
      if (isBlacklistedPost) {
        setMessage("Snack gick inte att skapa");
        SetIsLoading(false)
      } else {
        await axios
          .post(process.env.REACT_APP_REQUEST_URL + "/post/create-post", {
            postContent: post,
            user: { id: parseInt(userId) },
          })
          .then(async (resp) => {
            if (resp.data !== 0) {
              postId = resp.data;

              if (images.length > 0) {
                const formData = new FormData();
                images.forEach((image) => {
                  formData.append("images", image);
                });

                formData.append("post-id", postId);

                await axios
                  .post(process.env.REACT_APP_REQUEST_URL + "/Image/upload", formData, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  })
                  .then((response) => {
                    console.log(response);
                  });
              }

              if (voteArray.length > 0) {
                await axios
                  .post(process.env.REACT_APP_REQUEST_URL + "/post/create-poll/" + postId, voteArray)
                  .then((resp) => {
                    console.log(resp.data);
                  });
              }
              SetIsLoading(false)
              setMessage("Snack Skapad. Trevlig dag!");
              navigate("/snack/" + postId);
            } else {
              setMessage("Snack gick ej att skapa. Vänta 1 minut och försök igen.");
              SetIsLoading(false)
            }
          });
      }
    } else {
      setMessage("För kort snack. Skapa ett längre snack och försök igen.");
      SetIsLoading(false)
    }
  }

  function submitVote(e) {
    const isBlacklistedPost = isBlacklisted(voteInput);

    if (e.key === "Enter") {
      if (
        voteArray.length < 12 &&
        !voteArray.includes(voteInput) &&
        voteInput.length > 0 &&
        !isBlacklistedPost
      ) {
        setVoteArray([...voteArray, voteInput]);
        setVoteInput("");
      }
    }
  }

  function setMessage(message) {
    setPostCreated(true);
    setPostCreatedMessage(message);

    setTimeout(() => {
      setPostCreated(false);
    }, 6000);
  }

  function removeFromArray(item) {
    setVoteArray(voteArray.filter((vote) => vote !== item));
  }

  const handleCheckboxChange = () => {
    setVoteChecked(!voteChecked);
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    if (selectedImage != null) {
      if (selectedImage.size < 15728640) {
        if (images.length < 10) setImages([...images, selectedImage]);
      } else {
        alert("Please select an image smaller than 1 MB.");
      }
    }
  };

  return (
    <div id="site">
  <Header />
  <div className="container">
    <div className="content">
    <div id="newpost_textarea">
            <div className="toolbar">
              <button onClick={() => editor.chain().focus().toggleBold().run()} disabled={!editor.can().chain().focus().toggleBold().run()}>Bold</button>
              <button onClick={() => editor.chain().focus().toggleItalic().run()} disabled={!editor.can().chain().focus().toggleItalic().run()}>Italic</button>
              <button onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()} className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}>H1</button>
              <button onClick={() => editor.chain().focus().toggleBulletList().run()} className={editor.isActive('bulletList') ? 'is-active' : ''}>Bullet List</button>
              <button onClick={() => editor.chain().focus().toggleOrderedList().run()} className={editor.isActive('orderedList') ? 'is-active' : ''}>Ordered List</button>
              <input type="color" onChange={(e) => applyTextColor(e.target.value)} title="Choose text color" />
            </div>
            <EditorContent editor={editor} id="newpost_text"/>
    </div>

      <div id="new-post-images-area">
        <label htmlFor="imageInput" className="custom-button-label">
          <p className="custom-button">Välj bild</p>
        </label>
        <input
          type="file"
          accept="image/jpeg, image/png"
          id="imageInput"
          onChange={handleImageChange}
          style={{ display: "none" }}
        />
        <span className="image-grid">
          {images.map((image, currentImageIndex) => (
            <span key={currentImageIndex}>
              <button
                id="new-post-images"
                onClick={() =>
                  setImages((prevImages) =>
                    prevImages.filter((_, index) => index !== currentImageIndex)
                  )
                }
              >
                {image.name.length > 10
                  ? `${image.name.substring(0, 7)}...`
                  : image.name}
              </button>
            </span>
          ))}
        </span>
      </div>

      <button id="create-post-button" onClick={submitPost} disabled={loading}>
      {loading ? (
        <div className="loader"></div> 
      ) : (
        "Skapa snack"
      )}
    </button>
      {postCreated ? (
        <div id="post-creation-message">{postCreatedMessage}</div>
      ) : null}
    </div>

    <div className="sidebar">
      <div className="pretty-slider-checkbox">
        <label className={`slider ${voteChecked ? "checked" : ""}`}>
          <input
            type="checkbox"
            checked={voteChecked}
            onChange={handleCheckboxChange}
          />
          <div className="slider-circle"></div>
        </label>
        <p id="slider-label">Lägg till röstning på post.</p>
      </div>

      {voteChecked && (
        <div>
          <input
            type="text"
            placeholder="Lägg till röstval"
            value={voteInput}
            maxLength={50}
            className="vote-input"
            onChange={(e) => setVoteInput(e.target.value)}
            onKeyDown={(e) => submitVote(e)}
          />
        </div>
      )}

      {voteChecked && (
        <div id="vote-buttons-container">
          {voteArray.map((vote) => (
            <div className="vote-label" key={vote}>
              <span className="vote-text">{vote}</span>
              <button
                className="remove-button"
                onClick={() => removeFromArray(vote)}
              >
                &#x2715;
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  </div>
</div>

  );
}

export default NewPost;
