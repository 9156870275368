import React, { useRef, useEffect } from 'react';
import { Chart } from 'chart.js/auto';

const PollChart = ({ data, options }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');
    const chartInstance = new Chart(ctx, {
      type: 'pie', // Change this to your desired chart type
      data: data,
      options: options,
    });

    return () => {
      chartInstance.destroy();
    };
  }, [data, options]);

  return <canvas ref={canvasRef}></canvas>;
};

export default PollChart;

