import { useAuth0 } from '@auth0/auth0-react';

function TestLogin(){
    const { loginWithRedirect, logout, user, isAuthenticated } = useAuth0();

    return(
        <div>
          {!isAuthenticated && (
            <button onClick={() => loginWithRedirect()}>Log In</button>
          )}
          {isAuthenticated && (
            <div>
            <button onClick={() => logout({ returnTo: window.location.origin })}>
              Log Out
            </button>
                      <div>
                      <p>Name: {user.name}</p>
                      <p>Email: {user.email}</p>
                    </div>
            </div>
          )}
        </div>
    );
}

export default TestLogin;