import "./Vote.css";
import axios from "axios";
import { useState, useEffect } from "react";

function Vote(input) {
  const [poll, setPoll] = useState([]);
  const [loggedIn, setLoggedIn] = useState();

  useEffect(() => {
    if(sessionStorage.getItem("loggedIn") == null)
      {
        setLoggedIn("false");
      }
      else{
        setLoggedIn(sessionStorage.getItem("loggedIn"));    
      }    
      const handleLocalStorageUpdate = () => {
    };

    // Add event listener for custom event
    window.addEventListener('localStorageUpdate', handleLocalStorageUpdate);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('localStorageUpdate', handleLocalStorageUpdate);
    };
  }, []);


  useEffect(() => {
    async function GetPoll()
    {
      if(loggedIn != "false")
        {
          await axios.get(process.env.REACT_APP_REQUEST_URL + "/post/get-poll/" + parseInt(input.values.id)).then((resp) => {
            setPoll(resp.data);
          });
        }
    }
    GetPoll();
  }, [input.values.id]);

  async function vote(event) {
    await axios
      .post(process.env.REACT_APP_REQUEST_URL + "/post/vote/" + parseInt(input.values.userId) + "/" + parseInt(input.values.id) + "/" + event.voteId)
      .then((resp) => {
        console.log(resp);
      });
  }

  return (
    <div id="popup-overlay">
        <div id="vote-popup">
        {poll.map((index) => (
          <div id="vote-page">
            <div>
              <button id="vote-button" onClick={() => vote(index)}>
                {index.description}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Vote;