import "./App.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import FrontPage from "./pages/frontpage/FrontPage";
import Post from "./pages/post/Post";
import NewPost from "./pages/post/NewPost";
import UserProfile from "./pages/UserSearch/UserProfile";
import TestLogin from "./pages/TestLogin";
import {useEffect} from "react";
import { Auth0Provider } from '@auth0/auth0-react';

function App() {
  const domain = "dev-g7567x0p.eu.auth0.com";
  const clientId = "UVKtBTySkZDK3C8YgWV9HuqQKC52M0JI";
  return (
    <Auth0Provider
    domain={domain}
    clientId={clientId}
    redirectUri={window.location.origin}
  >
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<FrontPage />} />
          <Route path="/snack/:id" element={<Post />} />
          <Route path="/skapa" element={<NewPost />} />
          <Route path="/profil/:userId" element={<UserProfile />}/>
          <Route path="/login" element={<TestLogin/>}/>
        </Routes>
      </BrowserRouter>
    </div>
    </Auth0Provider>
  );
}

export default App;
